/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-return-assign */
/* eslint-disable indent */
/* eslint-disable no-nested-ternary */

import useMediaQuery from '@material-ui/core/useMediaQuery';
// import LeftArrowIcon from '@material-ui/icons/ChevronLeft';
// import RightArrowIcon from '@material-ui/icons/ChevronRight';
import { useRef, useState } from 'react';
import Slider from 'react-slick';
import Icon from '@theme/icons/icon';

const ProductSlider = (props) => {
    // prettier-ignore
    const {
        children,
        owl_active,
        owl_auto_height,
        owl_autoplay_timeout,
        owl_dots,
        owl_dots_speed,
        owl_item_xl,
        owl_item_lg,
        owl_item_md,
        owl_item_sm,
        owl_item_xs,
        owl_lazyload,
        owl_loop,
        owl_nav,
        owl_nav_position,
        owl_nav_size,
        owl_stage_padding,
        owl_active_background_color,
        owl_background_color,
        owl_color,
        owl_hover_background_color,
        owl_hover_color,
        owl_autoplay,
        owl_autoplay_hover_pause,
        el_class,
    } = props;

    const navSize = owl_nav_size === 'mini' ? 10 : owl_nav_size === 'small' ? 15 : owl_nav_size === 'normal' ? 20 : 25;
    const [showNav, setShowNav] = useState(true);

    const isXl = useMediaQuery('(min-width:1200px)');
    const isLg = useMediaQuery('(min-width:992px) and (max-width:1199px)');
    const isMd = useMediaQuery('(min-width:768px) and (max-width:991px)');
    const isSm = useMediaQuery('(min-width:576px) and (max-width:767px)');
    const isXs = useMediaQuery('(max-width:576px)');
    let sliderRef = useRef();

    const getItemsToShow = () => {
        let itemsToShow = 1;

        if (isXl && owl_item_xl) itemsToShow = owl_item_xl;
        if (isLg && owl_item_lg) itemsToShow = owl_item_lg;
        if (isMd && owl_item_md) itemsToShow = owl_item_md;
        if (isSm && owl_item_sm) itemsToShow = owl_item_sm;
        if (isXs && owl_item_xs) itemsToShow = owl_item_xs;

        return itemsToShow;
    };

    const settings = {
        autoplay: owl_autoplay,
        autoplaySpeed: owl_autoplay_timeout,
        speed: owl_dots_speed || 1000,
        dots: owl_dots,
        infinite: owl_loop,
        arrows: false,
        lazyload: owl_lazyload ? 'ondemand' : null,
        pauseOnHover: owl_autoplay_hover_pause,
        adaptiveHeight: owl_auto_height || false,
        customPaging: (i) => (
            <a key={i} rel="nofollow" href="?">
                <div className="custom-slick-dots" />
            </a>
        ),
        slidesToShow: getItemsToShow(),
        slidesToScroll: getItemsToShow() || 1,
        initialSlide: owl_active ? owl_active - 1 : 0,
        onReInit: () => {
            if (isXl || document.querySelector('.slick-dots')) {
                setShowNav(true);
            } else {
                setShowNav(false);
            }
        },
    };

    return (
        <>
            <div className={`mgz-product-slider ${el_class || ''}`}>
                <div className="mgz-product-slider-content">
                    <Slider ref={(slider) => (sliderRef = slider)} {...settings}>
                        {children}
                    </Slider>
                    {owl_nav && showNav && children.length > 0 && (
                        <div className="mgz-product-slider-nav">
                            <div className="mgz-product-slider-nav--btn" onClick={() => sliderRef.slickPrev()}>
                                {/* <LeftArrowIcon /> */}
                                <Icon icon="left-arrow" size={18} />
                            </div>
                            <div className="mgz-product-slider-nav--btn" onClick={() => sliderRef.slickNext()}>
                                {/* <RightArrowIcon /> */}
                                <Icon icon="right-arrow" size={18} />
                            </div>
                        </div>
                    )}
                </div>
            </div>

            <style jsx>
                {`
                    .mgz-product-slider {
                        min-height: 298.38px;
                    }
                    .mgz-product-slider :global(img) {
                        max-width: 100%;
                    }
                    .mgz-product-slider :global(.slick-slide) {
                        min-height: 245px;
                        height: auto;
                        position: relative;
                        padding: 10px 7px;
                    }
                    @media (max-width: 768px) {
                    }
                    @media (min-width: 768px) {
                        .mgz-product-slider.with-banner :global(.slick-slide) {
                            height: auto;
                        }
                        .mgz-product-slider :global(.slick-slide) {
                            height: auto;
                        }
                    }
                    .mgz-product-slider :global(.slick-slider) {
                        padding: 0 ${owl_stage_padding}px;
                    }
                    .mgz-product-slider :global(.slick-list) {
                        margin: 0 -7px;
                    }
                    .mgz-product-slider :global(.slick-dots) {
                        position: relative;
                    }
                    .mgz-product-slider :global(.slick-dots li) {
                        padding: 0 6px;
                    }
                    .mgz-product-slider :global(.slick-track) {
                        display: flex;
                        flex-direction: row;
                        flex-wrap: nowrap;
                        align-items: stretch;
                        height: inherit;
                    }
                    .mgz-product-slider :global(.custom-slick-dots) {
                        width: 10px;
                        height: 10px;
                        background-color: ${owl_background_color || '#eee'};
                        border-radius: 50px;
                    }
                    .mgz-product-slider :global(.slick-active .custom-slick-dots) {
                        background-color: ${owl_active_background_color || '#000000'};
                    }
                    .mgz-product-slider :global(.slick-slider li:not(.slick-active) .custom-slick-dots:hover) {
                        background-color: ${owl_hover_background_color || '#000000'};
                    }
                    .mgz-product-slider-content {
                        text-align: center;
                        ${owl_nav_position.includes('bottom') && (isXs || isSm) ? 'position: relative;' : ''}
                    }
                    .mgz-product-slider-nav {
                        position: absolute;
                        top: ${owl_nav_position.includes('top') ? (isXs || isSm ? '2%' : '10%') : '50%'};
                        bottom: ${owl_nav_position.includes('bottom') ? '-10%' : '50%'};
                        left: 50%;
                        transform: translateX(-50%);
                        display: flex;
                        width: 104%;
                        justify-content: ${owl_nav_position === 'top_left' || owl_nav_position === 'bottom_left'
                            ? 'flex-start'
                            : owl_nav_position === 'top_right' || owl_nav_position === 'bottom_right'
                            ? 'flex-end'
                            : 'space-between'};
                    }
                    .mgz-product-slider-nav--btn {
                        display: flex;
                        z-index: 1;
                        margin: 0 2px;
                        opacity: 1;
                        align-items: center;
                        justify-content: center;
                        // width: ${navSize * 2}px;
                        // height: ${navSize * 2}px;
                        width: 38px;
                        height: 38px;
                        background-color: ${owl_background_color || '#eee'};
                        transition: opacity 0.3s ease-in-out, background-color 0.3s ease-in-out, color 0.3s ease-in-out;
                        border-radius: 50%;
                    }
                    .mgz-product-slider:hover .mgz-product-slider-nav--btn {
                        ${owl_nav_position === 'center_split' ? 'opacity: 1;' : ''}
                    }
                    .mgz-product-slider-nav--btn:hover {
                        cursor: pointer;
                        background-color: ${owl_hover_background_color};
                    }
                    .mgz-product-slider-nav--btn :global(svg) {
                        font-size: 15px;
                        color: ${owl_color};
                        width: 2rem;
                        height: 2rem;
                    }
                    .mgz-product-slider-nav--btn:hover :global(svg) {
                        color: ${owl_hover_color};
                    }
                    .mgz-product-slider-dots {
                        display: flex;
                        justify-content: center;
                        margin: 5px;
                    }
                `}
            </style>
            <style jsx global>
                {`
                    .slick-slide .mgz-single-product-card > .MuiGrid-container{
                        height: 100%;
                    }
                    .mgz-product-slider-nav {
                        bottom: unset !important;
                        transform: translate(-50%, -50%) !important;
                    }
                    #flash_sale .mgz-element-inner {
                        height: 100%;
                    }

                    #best-seller {
                        padding: 30px 0;
                    }
                    #best-seller .mgz-product-slider :global(.slick-slider) {
                        padding: 0;
                    }
                    .flashsale-wrapper .col-sm-12:nth-child(1) {
                        padding-right: 8px;
                        padding-top: 20px;
                        padding-bottom: 20px;
                        max-height: 32vw;
                    }
                    #best-seller .col-sm-12:nth-child(1) .mgz-element {
                        height: 100%;
                    }
                    #best-seller .col-sm-12:nth-child(1) .mgz-element-inner {
                        padding: 10px 0;
                    }
                    #best-seller .col-sm-12:nth-child(1) .magezon-image img {
                        border-radius: 8px;
                        box-shadow: 0px 2px 10px #0000001A;
                    }
                    #best-seller #best-seller-banner * {
                        height: 100% !important;
                        width: 100% !important;
                    }
                    @media (max-width: 576px) {
                        #best-seller .col-sm-12,
                        .flashsale-wrapper .col-sm-12 {
                            flex-basis: 50% !important;
                            width: 50% !important;
                        }
                    }
                    @media (max-width: 767px) {
                        .flashsale-wrapper > .row
                        #best-seller .row{
                            flex-wrap: nowrap;
                        }
                        #best-seller .col-md-15,
                        .flashsale-wrapper .col-md-15 {
                            flex-basis: 33.3%;
                            width: 33.3%;
                        }

                        #best-seller .col-md-45,
                        .flashsale-wrapper .col-md-45 {
                            flex-basis: 66.6%;
                            width: 66.6%;
                        }
                        #best-seller .col-sm-12:nth-child(1),
                        .flashsale-wrapper .col-sm-12:nth-child(1) {
                            padding-right: 7px;
                            max-height: 90vw;
                        }
                        #best-seller .col-sm-12:nth-child(2),
                        .flashsale-wrapper .col-sm-12:nth-child(2) {
                            padding-left: 7px;
                            max-height: 90vw;
                        }
                        #best-seller .with-banner,
                        .flashsale-wrapper .with-banner {
                            min-height: auto !important;
                        }       
                        #best-seller .with-banner .MuiGrid-item,
                        .flashsale-wrapper .with-banner .MuiGrid-item{
                            justify-content: center !important;
                        }   
                        #best-seller .with-banner,
                        #best-seller .with-banner > .mgz-element-inner {
                            height: 100%;
                        }
                        .mgz-product-slider .mgz-product-slider-nav{
                            top: calc(50% - 20px) !important;
                        }   
                        .mgz-product-slider .mgz-product-slider-nav .mgz-product-slider-nav--btn{
                            background: transparent;
                        }                     
                    }
                    @media (max-width: 991px) {
                        .mgz-product-slider :global(.slick-list) {
                            margin: 0;
                        }
                    }
                    @media (min-width: 768px) and (max-width: 991px) {
                        #best-seller .col-sm-12:nth-child(1),
                        .flashsale-wrapper .col-sm-12:nth-child(1) {
                            flex: 1 25%;
                            max-width: 25%;
                            padding-right: 15px;
                            max-height: 78vw;
                        }
                        #best-seller .col-sm-12:nth-child(2),
                        .flashsale-wrapper .col-sm-12:nth-child(2) {
                            flex: 1 75%;
                            max-width: 75%;
                            max-height: 78vw;
                        }
                    }
                    @media (min-width: 992px) and (max-width: 1023px) {
                        #best-seller .col-sm-12:nth-child(1),
                        .flashsale-wrapper .col-sm-12:nth-child(1) {
                            flex: 1 20%;
                            max-width: 20%;
                            padding-right: 10px;
                            max-height: 35vw;
                        }
                        #best-seller .col-sm-12:nth-child(2),
                        .flashsale-wrapper .col-sm-12:nth-child(2) {
                            flex: 1 80%;
                            max-width: 80%;
                            max-height: 35vw;
                        }
                    }
                    @media (max-width: 1200px) {
                        .flashsale-wrapper .col-sm-12:nth-child(1) *:not(.counterWrapper *),
                        #best-seller .col-sm-12:nth-child(1) * {
                            height: 100%;
                        }
                        #best-seller .col-sm-12:nth-child(1) picture,
                        .flashsale-wrapper .col-sm-12:nth-child(1) picture{
                            display: block;
                        }
                        #best-seller .col-sm-12:nth-child(1) img,
                        .flashsale-wrapper .col-sm-12:nth-child(1) img{
                            height: 100% !important;
                        }
                    }
                    @media (min-width: 1201px) {
                        #best-seller .col-sm-12:nth-child(1), 
                        .flashsale-wrapper .col-sm-12:nth-child(1) {
                            padding-right: 10px;
                        }
                        #best-seller .col-sm-12:nth-child(1) {
                            
                        }
                        #best-seller #best-seller-banner * {
                            max-height: 375.14px;
                        }
                    }
                    @media (max-width: 424px) {
                        #best-seller .col-sm-12:nth-child(1) img,
                        .flashsale-wrapper .col-sm-12:nth-child(1) img{
                            object-fit: cover;
                            border-radius: 8px
                        }
                    }
                `}
            </style>
        </>
    );
};

export default ProductSlider;
